<template>
	<div>
		<section class="section intro">
			<div class="level">
				<div class="level-left">
					<h1 class="title has-text-centered-mobile is-size-5-mobile">
						{{ $t("View Cities") }}
					</h1>
				</div>
				<div class="level-right">
					<div class="level-item">
					</div>
				</div>
			</div>
		</section>
		<section class="section">
			<b-table
				:current-page.sync="currentPage"
				:data="normalizedCities"
				:debounce-search="1000"
				:default-sort-direction="defaultSortDirection"
				:paginated="isPaginated"
				:pagination-position="paginationPosition"
				:pagination-rounded="isPaginationRounded"
				:pagination-simple="isPaginationSimple"
				:per-page="perPage"
				:sort-icon="sortIcon"
				:sort-icon-size="sortIconSize"
				aria-current-label="Current page"
				aria-next-label="Next page"
				aria-page-label="Page"
				aria-previous-label="Previous page"
				default-sort="projectName"
			>
				<b-table-column
					v-slot="props"
					:width="100"
					field="id"
					label="ID"
					numeric
					searchable
					sortable
				>
					{{ props.row.id }}
				</b-table-column>

				<b-table-column
					v-slot="props"
					label="Localitate"
					field="value"
					searchable
					sortable
				>
					<strong>{{ props.row.value }}</strong>
				</b-table-column>		
			</b-table>
		</section>
		<b-loading v-model="isLoading"></b-loading>
	</div>
</template>

<script>

export default {
	name: "ViewCities",
	computed: {
	},
	data() {
		return {
			normalizedCities: [{
                id:10,
                value: "Agigea"
            },
            {
                id:11,
                value: "Murfatlar"
            },
            {
                id:12,
                value: "Sibiu"
            },
            {
                id:13,
                value: "Selimbar"
            }],
			isLoading: false,
			isPaginated: true,
			isPaginationSimple: false,
			isPaginationRounded: false,
			paginationPosition: "bottom",
			defaultSortDirection: "asc",
			sortIcon: "arrow-up",
			sortIconSize: "is-small",
			currentPage: 1,
			perPage: 20,
		};
	},
	mounted() {
	},
	watch: {
	},
	methods: {
	},
};
</script>

<style scoped>
</style>
